import React from 'react';
import ReactDOM from 'react-dom';

import AboutSection from './AboutSection';
import CardInfoSection from './CardInfoSection';
import HeroSection from './HeroSection';
import './index.css';
import TopBar from './TopBar';
import {data} from './CardInfoSection/data';
import ContactsSection from './ContactsSection';
import NewsSection from './NewsSection';

ReactDOM.render(
  <React.StrictMode>
    <TopBar/>
    <HeroSection></HeroSection>
    <AboutSection/>
    <NewsSection/>
    <CardInfoSection content = {data}></CardInfoSection>
    <ContactsSection></ContactsSection>
  </React.StrictMode>,
  document.getElementById('root')
);
