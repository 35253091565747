import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Mark McMillan
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        14/10/21 18:00 to 19:00 BST. Yusuf Hamied Theatre (and Zoom)
                    </SubHeading>

                    <SubHeading>
                        Description: Adventures in Automated Trading: I’m an Engineer, honest!
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Mark McMillan is Global Head of Algorithmic Trading Quants at Standard Chartered. After graduating from St. John’s College, Oxford, with a Masters in Engineering he has gone on to work as an embedded engineer, a software developer and for approaching 14 years as a quantitative analyst in automated trading. He has a particular professional interest in Diversity and Inclusion.
                    </Text>


                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
