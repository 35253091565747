import React, {useState} from 'react'
import { AboutContainer, About, AboutPillarWrapper, Portrait, AboutInfoContainer, Text, SubHeading, TabsContainer, TabContainer, AboutPortraitWrapper } from './AboutElements'

import AvatarImageJordan from '../Images/jordan_profile.jpg'
import AvatarImageAdi from '../Images/adi_profile.png'
import AvatarImageBen from '../Images/ben_profile.jpg'
import {Button} from '../ButtonElement'
import SocialSection from '../SocialSection'

const AboutSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <>
            <About >
            
                <AboutContainer id='about_section'>
                   

                    <SubHeading>
                        Who are we?
                    </SubHeading>


                    
                    <Text style={{padding: '10px', textAlign: "center", maxWidth: "700px", color: '#5B87AE'}}>
                        CCES is a student-run society based in Christ’s College, Cambridge. We strive to provide opportunities for students to explore the rich field of engineering outside the lecture halls. We do so by focusing on the <span style={{fontWeight: 'bold'}}> 3 pillars that form the foundation of our society </span>:
                    </Text>

                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        
                        <AboutPillarWrapper>
                            <Text style={{fontWeight: 'bold'}}> - CCES Lecture Series - </Text>
                            <Text>This initiative focuses on exposing students to fascinating issues and topics that are not covered in our degrees. We <span style={{fontWeight: 'bold'}}> invite guest speakers </span> from around the world from various backgrounds and professions to give the students a glimpse into their fields and achievements.</Text>
                        </AboutPillarWrapper>
                        <AboutPillarWrapper>
                            
                            <Text style={{fontWeight: 'bold'}}> - Project talks - </Text>
                            <Text>Through this initiative we facilitate the transfer of knowledge between students at our college. We <span style={{fontWeight: 'bold'}}> invite students currently undertaking a Masters project or a PhD </span> to talk about their research with the aim of inspiring other students.</Text>
                            
                        </AboutPillarWrapper>
                        <AboutPillarWrapper>
                            
                            <Text style={{fontWeight: 'bold'}}> - Social integration - </Text>
                            <Text>Technical prowess aside, networking is a key skill that all engineers (or anyone) should practice. CCES <span style={{fontWeight: 'bold'}}> hosts several events </span>  during the academic year for students and academics to share experiences, discover mutual interests, and get career advice in a more casual atmosphere.</Text>
                        </AboutPillarWrapper>
                    </div>
                    

                    <SubHeading>
                        Meet the Commitee!
                    </SubHeading>

                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        
                        <AboutPortraitWrapper>
                            <Portrait src={AvatarImageBen} alt="Avatar"/>
                            <Text style={{fontWeight: 'bold'}}> - Vice and Webmaster - </Text>
                            <Text>Ben Zandonati</Text>
                        </AboutPortraitWrapper>
                        <AboutPortraitWrapper>
                            <Portrait src={AvatarImageAdi} alt="Avatar"/>
                            <Text style={{fontWeight: 'bold'}}> - President - </Text>
                            <Text>Aditya Jain</Text>
                            
                        </AboutPortraitWrapper>
                        <AboutPortraitWrapper>
                            <Portrait src={AvatarImageJordan} alt="Avatar"/>
                            <Text style={{fontWeight: 'bold'}}> - Social Sec - </Text>
                            <Text>Joshua Szarowicz</Text>
                        </AboutPortraitWrapper>
                    </div>

                    
                    <SocialSection></SocialSection>

                    <hr width="30%" size="1" align="center" color="#727F8B"></hr>
                    
                    
                    
                    
                </AboutContainer>
                
            </About>
            
        </>
    )
}

export default AboutSection
