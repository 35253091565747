import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Claudio Galeazzi
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        10/11/21 17:30 to 19:00 GMT. Yusuf Hamied Theatre (<a href='https://us02web.zoom.us/j/83971448169?pwd=MDNvbU40WWYzZXFiZG1TMFBzeEo3QT09'>and Zoom</a>)
                    </SubHeading>

                    <SubHeading>
                        Description: European Space Agency: taking the pulse of the Earth
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Claudio Galeazzi is the Project Manager of the CIMR Satellites at the European Space agency (ESA) in The Netherlands. After graduating in Aeronautical Engineering at the University of Rome La Sapienza, he got a second degree in Aerospace in Rome and a Master in Space Systems Engineering at the University of Delft in The Netherlands. He has been working in the Space sector for almost 30 years in various roles (Mechanical Engineer, System Engineer and Project Manager) at Thales Alenia Space, Italian Space Agency and, finally, ESA.
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
