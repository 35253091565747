import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Prof. Simone Severini
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        The talk has now finished!
                    </SubHeading>

                    <SubHeading>
                        Description: Quantum Computing: Keep it real
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Professor Simone Severini, Professor of Physics of Information at University College London, is the Director of Quantum Computing at Amazon Web Services. After recieving his Phd at the Quantum Information and Computation Group at the University of Bristol, he then went on to work at the Institute for Quantum Computing in Waterloo, Canada and then subsequently in institutions around the world, including none other than the University of Cambridge. Since joining AWS in 2018, he has contributed to the launch of Amazon Braket, the AWS Center for Quantum Computing at Caltech and the Quantum Solutions lab.   
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
