import React, {useState} from 'react'
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ArrowHoverDown, ArrowDown, STLviewerContainer, STLviewerInstructionsWrapper, IMGBg} from './HeroElements'
import './typeWriter.css';
import background from "../Images/christs_hero.jpg"
import {Button} from '../ButtonElement'
var ReactRotatingText = require('react-rotating-text');
const HeroSection = () => {


    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    
    return (
        <HeroContainer id="home">
            <HeroBg>
                <IMGBg src={background}/>
                
            </HeroBg>
            
            <HeroContent>
                <HeroH1>C C E S</HeroH1>
                <HeroP><ReactRotatingText pause={1500} items={["Welcome to CCES!", 'Scroll down to see the latest ...']} /></HeroP>
                
                {/* <HeroP>Christ's College Engineering Society</HeroP> */}
                <HeroBtnWrapper>
                    <Button to='latest_news' onMouseEnter={onHover} onMouseLeave={onHover} 
                        primary='true'
                        

                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-110}
                    >
                        Latest {hover ? ' >' : ''}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection

