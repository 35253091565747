import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'
import ReactPlayer from "react-player"



const TestModal = () => {
    return (
        <>

                <Heading>
                    Prof. Anthony Finkelstein CBE FREng DSc
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        The talk has finished!
                    </SubHeading>
                    
                    <SubHeading>
                        Description: Engineering Strategic Advantage in National Security 
                    </SubHeading>

                    <Text>
                        Science, Engineering and Technology have moved to the centre of the national security agenda. It is a domain of strategic competition between states. How can the UK secure an edge in an uncertain global environment? This talk (with Q+A) will introduce the challenges of National Security and consider the technology, policy and strategy options available to the UK. I will talk about career opportunities in National Security and about what engineers can contribute.
                    </Text>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Anthony Finkelstein CBE FREng DSc is Chief Scientific Adviser for National Security, HM Government. He is a Fellow of The Alan Turing Institute and holds a chair at UCL. He is a Fellow of the Royal Academy of Engineering. He served as Dean of Engineering and Head of Computer Science at UCL. He is President-elect of City, University of London.
                    </Text>
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
