
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 300px;
  height: 100%;
  word-wrap: break-word;
  background-color: #F6F7F8;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  overflow: hidden;
  transition: transform 250ms;
    

    &:hover {
        transform: translateY(-2px);
    }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.25rem;

`;

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  color: #131313;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  line-height: 18px;
  color: #6c757d;
  font-size: 0.8rem;
`;

export const Image = styled.img`
  width: 100%;
`;

export const ImageContainer = styled.div`
    display: flex;
    max-height: 200px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
`;

export const Button = styled.button`
    margin-top: auto;
    //border-radius: 50px;
    background: #BDD7EE;
    white-space: nowrap;
    padding: 12px 30px;
    color: #131313;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #131313;
        color: #EFF1F3;

    }
`;

export const Tags = styled.p`
  line-height: 20px;
  font-size: 0.8rem;
  color: #5B87AE;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const TagWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;