import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                Prof. John Loughhead CB OBE FREng FIMechE FIET 
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        The talk has finished! Check out the recording below:
                    </SubHeading>

                    <Text>
                        <a href={"https://youtu.be/lq_ONSKrUuE"}>Energy in a Warming World. Prof' John Loughhead</a>
                    </Text>

                    <SubHeading>
                        Description: Energy in a warming world
                    </SubHeading>

                    <Text>
                    The international debate around global warming has moved from our confidence in its reality to what actions the international community are prepared to take to mitigate its progress and adapt to the inevitable impacts. In November at COP26 revised commitments will be sought following a growing number of large emitters claiming to support a move to Net Zero emissions. This talk will address what such targets will mean for the global economy, how they might be met, and whether this is a challenge primarily for technology in the energy sector or for innovation more widely.
                    </Text>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Professor John Loughhead was until recently Chief Scientific Adviser at the Dept for Business Energy and Industrial Strategy, after two years in the same position at the Dept for Energy and Climate Change. He was previously Executive Director of the UK Energy Research Centre following an industrial career ending as Vice-President IP and Technology for the Alstom Group in Paris. He is a mechanical engineer with a research background in computational fluid dynamics, a Fellow of the national engineering academies of the UK, Australia and China, and Past-President of the Institution of Engineering and Technology.
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
