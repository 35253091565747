import React, {useState} from 'react'
import { NewsContainer, News, NewsPortraitWrapper, Portrait, NewsInfoContainer, Text, SubHeading, TabsContainer, TabContainer } from './NewsElements'

const NewsSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <>
            <News>
            
                <NewsContainer id='latest_news'>

                    <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#5B87AE', padding: '12px', fontWeight: 'bold', fontSize: '1.5rem'}}>
                    Latest News:
                    </a> 

                    <Text style={{padding: '10px', textAlign: "center", maxWidth: "700px", fontWeight: 'bold'}}>
                        - Register to attend the talks! -
                    </Text>

                    <Text style={{padding: '10px', textAlign: "center", maxWidth: "700px"}}>
                        
                        <a href={"https://docs.google.com/forms/d/e/1FAIpQLSeYXpYqK_eRy-n5cFSl0h-j15EVnShmpIdEu0fex-5JaK9tyw/viewform?usp=sf_link"}>Fill out the form to register your interest!</a>
                    </Text>

                    <hr width="30%" size="1" align="center" color="#727F8B"></hr>
                    
                    
                    
                    
                </NewsContainer>
                
            </News>
            
        </>
    )
}

export default NewsSection
