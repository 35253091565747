import styled from 'styled-components'
import keyframes from 'styled-components'
//import {MdKeyboardArrowRight, MdArrowForward, MdArrowDownward, MdKeyboardArrowDown} from 'react-icons/md'


export const HeroContainer = styled.div`
    background: #68798A;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;

    /* Add*/

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
        pointer-events: none;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index:1;
    
`;

export const STLviewerContainer = styled.div`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #68798A;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IMGBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #68798A;
`;

export const STLviewerInstructionsWrapper = styled.div`
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: 200px;
    border: 10px blue;
    z-index:4;
    align-items: left;
    opacity: ${({show}) => (show ? '100' : '0')};
    color: #68798A;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    padding: 12px;
    line-height: 20px;
    
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Avenir Next" !important;
`;

export const HeroH1 = styled.h1`
    margin-top: 20px;
    color: #EFF1F3;
    font-align: center;
    font-size: 48px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #EFF1F3;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// export const ArrowForward = styled(MdArrowForward)`
//     margin-left: 8px;
//     font-size: 20px;
// `;

// export const ArrowRight = styled(MdKeyboardArrowRight)`
//     margin-left: 8px;
//     font-size: 20px;
// `;

// export const ArrowHoverDown = styled(MdArrowDownward)`
//     margin-left: 8px;
//     font-size: 20px;
// `;

// export const ArrowDown = styled(MdKeyboardArrowDown)`
//     margin-left: 8px;
//     font-size: 20px;
// `;