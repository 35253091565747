import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Prof. Karen Willcox
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        The talk has now finished! Please see the recording below:
                    </SubHeading>

                    <Text>
                        <a href={"https://youtu.be/VnAmz8I9VkY"}>Predictive Digital Twins for Aerospace Systems - Prof. Karen Willcox</a>
                    </Text>

                    <SubHeading>
                        Description: Digital Twins and Unmanned Vehicles
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Karen E. Willcox is Director of the Oden Institute for Computational Engineering and Sciences and a Professor of Aerospace Engineering and Engineering Mechanics, at the University of Texas at Austin. She holds the W. A. “Tex” Moncrief, Jr. Chair in Simulation-Based Engineering and Sciences and the Peter O'Donnell, Jr. Centennial Chair in Computing Systems. Prior to joining the Oden Institute in 2018, she spent 17 years as a professor at the Massachusetts Institute of Technology, where she served as Professor of Aeronautics and Astronautics, the founding Co-Director of the MIT Center for Computational Engineering, and the Associate Head of the MIT Department of Aeronautics and Astronautics.
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
