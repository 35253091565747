import styled from 'styled-components';

export const ModalContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    
    width: 100%;
    max-width: 1100px;
    
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    
`;

export const ScrollWrapper = styled.div`
    overflow: scroll;
    padding: 15px 15px;
    min-width: 200px;
    margin: 15px;
    background-color: #F8F9FA;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.075);
`;

export const ModalWrapper = styled.div`
    display: flex;
    max-height: 90vh;
    
    border-radius: 0.25rem;
    background-clip: border-box;
    background:#EFF1F3;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;

`;

export const Button = styled.button`
    margin-top: auto;
    margin-bottom: 10px;
    //border-radius: 50px;
    background: #BDD7EE;
    white-space: nowrap;
    padding: 12px 30px;
    color: #131313;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #131313;
        color: #EFF1F3;

    }
`;

export const Heading = styled.h1`
    padding: 0px 30px;
    font-size: 48px;
    line-height: 1.3;
    color: #131313;
    margin-bottom: 12px;
    font-weight: light;
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const SubHeading = styled.p`
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #5B87AE;
`;
export const ImgWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Img = styled.img`
    max-width: 70%;
    margin: 10px;
    display: flex;
    
    
`;

export const CloseButton = styled.button`
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    //border-radius: 50px;
    width: 30px;
    color: #BDD7EE;
    //background: #131313;
    font-size: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #272727;
        

    }
`;

export const Text = styled.p`
  line-height: 24px;
  color: #6c757d;
  font-size: 1rem;
`;