import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Billy Boyle
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        08/03/22 18:00 to 19:00 GMT. Yusuf Hamied Theatre (<a href='https://us02web.zoom.us/j/83999472541?pwd=K1AzMjNTM2tkWS9NS1EwbFF1K3BEZz09'>and Zoom</a>)
                    </SubHeading>

                    <SubHeading>
                        Description: Breath Biopsy: Developing A Breathalyzer for Disease
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Billy Boyle is an engineering graduate from the University of Cambridge. He is one of the original co-founders of Owlstone Inc, spun out of Cambridge in 2004 with the goal of developing the applications of field asymmetric ion mobility spectroscopy (FAIMS).

 

                        Billy started to focus on the medical applications of FAIMS technology after his wife, Kate, was diagnosed and later died of colon cancer as a result of a late diagnosis. He worked closely with clinical partners who integrated FAIMS technology across a broad spectrum of research studies.

                         

                        Positive results helped to spin-out, and secure initial funding for, Owlstone Medical. With ongoing clinical and commercial success, Billy led this process and became the founding CEO upon the close of a $7M investment in March 2016. The mission of Owlstone Medical is to save 100,000 lives and $1.5B in healthcare costs. As of 2021, the company has secured over $150M of investment.

                         

                        Owlstone Medical has developed Breath Biopsy with the goal of creating non-invasive breath tests to support early detection and precision medicine of diseases including cancer, asthma, COPD and liver disease. The company works with leading academic institutions and industry leaders such as Astra Zeneca, J&J and GSK.

                         

                        Billy is also a judge for the Cancer Research UK (CRUK) Pioneer award and a trustee of the Linacre Institute. He was made a Fellow of the Royal Academy of Engineering in 2020 and an MBE in 2021.
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
