import styled from 'styled-components'

export const Bar = styled.div`
    width: 100%;
    height: 120px;
    background-color: #bdd7ee;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    z-index: +5;
`;

export const HeaderText = styled.p`
    font-size: 24px;
    color: #272727;
    font-family: "Avenir Next" !important;
    
    @media screen and (max-width: 800px){
        font-size: 20px;
    }

    @media screen and (max-width: 640px){
        display: none;
    }
`;

export const HeaderImg = styled.img`
    height: 80%;
    padding: 12px;
    margin-left: 24px;
    
`;