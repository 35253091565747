import React, {useState} from 'react'
import {Bar, HeaderText, HeaderImg} from './TopBarElements'
import pp_logo from '../Images/cces_logo.svg'
import icon from '../Images/menu.svg'
import { stack as Menu } from 'react-burger-menu'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import './styles.css';

const TopBar = () => {
    
    const showSettings = (e) => {
        //e.preventDefault();
    }
    
    const scrollTo = (e) => {
        // Somewhere else, even another file
        scroller.scrollTo('about_section', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -110, // Scrolls to element + 50 pixels down the page
        })
    }

    const scrollTo2 = (e) => {
        // Somewhere else, even another file
        scroller.scrollTo('latest_news', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -110, // Scrolls to element + 50 pixels down the page
        })
    }

    const scrollTo3 = (e) => {
        // Somewhere else, even another file
        scroller.scrollTo('contact_section', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -110, // Scrolls to element + 50 pixels down the page
        })
    }

    const scrollTo4 = (e) => {
        // Somewhere else, even another file
        scroller.scrollTo('latest_talks', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -110, // Scrolls to element + 50 pixels down the page
        })
    }


    return (
        <div>
            <Bar>
                

                <HeaderImg src={pp_logo}></HeaderImg>
                <HeaderText>Christ's College Engineering Society</HeaderText>
                

            </Bar>
            
            <Menu right customBurgerIcon={ <img src={icon} /> }>
                <a onClick={scrollTo} id="about" className="menu-item" >About</a>
                <a onClick={scrollTo2} id="latest" className="menu-item" >Latest</a>
                <a onClick={scrollTo4} id="lectures" className="menu-item" >CCES Lecture Series</a>
                <a onClick={scrollTo3} id="contact" className="menu-item" >Contact</a>
            </Menu>
            
           
        </div>
    )
}

export default TopBar
