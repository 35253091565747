import React from 'react'
import {ModalContainer, ModalWrapper, Heading, SubHeading, ScrollWrapper, Img, ImgWrap, Text, Button} from './ModalElements'




const TestModal = () => {
    return (
        <>

                <Heading>
                    Dr Andrew Blake
                </Heading>
                <ScrollWrapper>

                    <SubHeading>
                        04/03/22 17:00 to 18:00 GMT. Z Amenity Room (<a href='https://us02web.zoom.us/j/88173288358?pwd=TmFEVmxxeEk0dDVuNmFMUkNOclZMQT09'>and Zoom</a>)
                    </SubHeading>

                    <SubHeading>
                        Description: AI for Humans
                    </SubHeading>

                    <SubHeading>
                        Profile:
                    </SubHeading>

                    <Text>
                        Andrew Blake is a pioneer in the development of the theory and algorithms that make it possible for computers to behave as seeing machines. He is especially interested in visual segmentation and tracking, in dealing with uncertainty, and in real-time, 3D vision.

                        He trained in mathematics and electrical engineering in Cambridge UK and at MIT, and studied for a doctorate in Artificial Intelligence at the University of Edinburgh. He was an academic for 18 years, in Edinburgh and Oxford, ultimately as Professor of Information Engineering at Oxford University. He joined Microsoft in 1999 to found the Computer Vision group in Cambridge, before becoming Director of Microsoft’s Cambridge Laboratory in 2010 and a Microsoft Distinguished Scientist.  

                        Currently he is a consultant in Artificial Intelligence: Chairman of Samsung’s AI Research Centre SAIC in Cambridge UK; Scientific Adviser to the FiveAI autonomous driving company; and Chief Scientific Adviser at Mantle Labs. Previously he consulted for Siemens and the UK Stock Exchange.
                    </Text>

                    

                    
                
                    
                
                


                

                </ScrollWrapper>
                
        </>
    )
}

export default TestModal
