import styled from 'styled-components'

export const SocialContainer = styled.div`
    width: auto;
    padding: 25px 20px;
`;

export const Facebook = styled.a`
    color: #4968ad;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;

    &:hover {
        transform: translateY(-2px);
    }
`;

export const Youtube = styled.a`
    color: #eb3223;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;

    &:hover {
        transform: translateY(-2px);
    }
`;