import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    background:#EFF1F3;
`;

export const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    height: 500px;
    padding: 15px 15px;
`;

export const CardContainerWrapper = styled.div`
    background:#EFF1F3;
    padding: 10px;
`;


