import React from 'react'
import { Card } from '../CardModal';
import propTypes from 'prop-types'
import { CardContainer, CardWrapper, CardContainerWrapper } from './CardInfoSectionElements'

const finished_talks = [1,2,3,4,5,6,7]

const upcoming_talks = [8,9]

const CardInfoSection = ({content}) => {

    const sorted = content.data.sort((a,b) => a.id - b.id)

    const back_sorted = content.data.sort((a,b) => b.id - a.id)

    const cards = content.data.map(project => {
            return(
                <CardWrapper>
                    <Card classes="mr" key={`${project.id}`}>
                    
                    <Card.Image src={project.image} alt={project.title} />
                    <Card.Tags> {project.alt} </Card.Tags>
                    <Card.Body>
                        <Card.Title>{project.title} <Card.Tags> {project.tags} </Card.Tags> </Card.Title>
                        
                        <Card.Text>{project.desc}</Card.Text>
                        
                        {/* <Card.Button>{project.ctaText}</Card.Button> */}
                        <Card.Modal button_content={project.ctaText} modal_content={project.modal}/>
                    </Card.Body>
                    </Card>
                </CardWrapper>
                
            )
        })
    
    const cards_up_next = sorted.map(
        project => {
            if (!finished_talks.includes(project.id)){
                return(
                    [
                    <CardWrapper>
                        <Card classes="mr" key={`${project.id}`}>
                        
                        <Card.Image src={project.image} alt={project.title} />
                        <Card.Tags> {project.alt} </Card.Tags>
                        <Card.Body>
                            <Card.Title>{project.title} <Card.Tags> {project.tags} </Card.Tags> </Card.Title>
                            
                            <Card.Text>{project.desc}</Card.Text>
                            
                            {/* <Card.Button>{project.ctaText}</Card.Button> */}
                            <Card.Modal button_content={project.ctaText} modal_content={project.modal}/>
                        </Card.Body>
                        </Card>
                    </CardWrapper>
                    ]
                )
            }
    })

    const cards_finished = back_sorted.map(
        project => {
            if (finished_talks.includes(project.id)){
                return(
                    [
                    <CardWrapper key={`${project.id}`}>
                        <Card classes="mr" >
                        
                        <Card.Image src={project.image} alt={project.title} />
                        <Card.Tags> {project.alt} </Card.Tags>
                        <Card.Body>
                            <Card.Title>{project.title} <Card.Tags> {project.tags} </Card.Tags> </Card.Title>
                            
                            <Card.Text>{project.desc}</Card.Text>
                            
                            {/* <Card.Button>{project.ctaText}</Card.Button> */}
                            <Card.Modal button_content={project.ctaText} modal_content={project.modal}/>
                        </Card.Body>
                        </Card>
                    </CardWrapper>
                    ]
                )
            }
    })
    
    return (
        <CardContainerWrapper id='latest_talks'>
            
            {/*<a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#5B87AE', padding: '12px', fontWeight: 'bold', fontSize: '1.5rem'}}>
                Next Up:
            </a> 
            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#6c757d', padding: '12px', fontWeight: 'bold'}}>
                The first CCES lecture series is now over. We would like to extend a massive thank you to our incredible speakers! 
            </a> 
            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#5B87AE', padding: '12px'}}>
                We constantly strive to get the best speakers from around the world, stay tuned for the latest!
            </a> 
            <CardContainer>
                {cards_up_next}
            </CardContainer>
            <hr width="30%" size="1" align="center" noShade  color="#727F8B"></hr>*/}
            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#5B87AE', padding: '12px', fontWeight: 'bold', fontSize: '1.5rem'}}>
                Upcoming Talks:
            </a> 
            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#6c757d', padding: '12px', fontWeight: 'bold'}}>
                CCES Series - Lent 2022:
            </a> 
            <CardContainer>
                {cards_up_next}
            </CardContainer>

            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#5B87AE', padding: '12px', fontWeight: 'bold', fontSize: '1.5rem'}}>
                Previous Talks:
            </a> 
            <a style={{display: 'flex', textAlign: "center", justifyContent: 'center', color: '#6c757d', padding: '12px', fontWeight: 'bold'}}>
                CCES Series - Lent/Michaelmas 2021:
            </a> 
            <CardContainer>
                {cards_finished}
            </CardContainer>
        </CardContainerWrapper>
    )
}

export default CardInfoSection
