import styled from 'styled-components'

export const About = styled.div`
    width: 100%;
    min-height: 200px;
    background: #EFF1F3;
`;

export const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;a
    min-height: 1px;
    padding: 12px;
    align-items: center;
    justify-content: center;
`;

export const AboutPortraitWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const Portrait = styled.img`
    border-radius: 50%;
    justify-content: center;
    height: 150px;
    width: 150px;
    align-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-shrink: 0.33;
`;

export const AboutInfoContainer = styled.div`
    margin-top: 20px;
    align-items: left;
    align-content: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
`;

export const Text = styled.p`
  line-height: 18px;
  color: #6c757d;
  font-size: 1rem;
    margin: 6px;
    flex-grow: 1;
`;

export const SubHeading = styled.p`
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
    color: #727F8B;
`;

export const TabsContainer = styled.div`
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    
`;

export const TabContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    width: 100%;
    max-width: 1100px;
    color: green;
    margin-right: auto;
    margin-left: auto;
    overflow: scroll;
    
`;