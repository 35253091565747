import React, {useState} from 'react'
import { AboutContainer, About, AboutPortraitWrapper, Portrait, AboutInfoContainer, Text, SubHeading, TabsContainer, TabContainer } from './ContactElements'

import AvatarImage from '../Images/CCES_logo1.png'
import {Button} from '../ButtonElement'
import SocialSection from '../SocialSection'


const ContactsSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <>
            <About >
                <AboutContainer id='contact_section'>
                    
                    <hr width="30%" size="1" align="center" color="#727F8B"></hr>

                    <SubHeading>
                        Feel free to get in contact!
                    </SubHeading>

                    <Text style={{padding: '10px', textAlign: "center", maxWidth: "700px"}}>
                        <a href="mailto:baz23@cam.ac.uk" style={{textDecoration: 'none', color: '#5B87AE'}}>Drop us an email!</a>
                    </Text>
                    
                    <SocialSection></SocialSection>
                    
                </AboutContainer>
                
            </About>
            
        </>
    )
}

export default ContactsSection
