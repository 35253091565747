import React from 'react'
import AF_photo from '../Images/AF.jpg'
import SS_photo from '../Images/SS.png'
import JL_photo from '../Images/JL.png'
import KW_photo from '../Images/KW.jpg'

import MM_photo from '../Images/MM.jpg'
import CG_photo from '../Images/CG.jpg'
import DJ_photo from '../Images/DJ.jpg'

import AB_photo from '../Images/AB.jpg'
import BB_photo from '../Images/BB.jpg'

const { default: AFModal } = require('./modals/AFModal')
const { default: SSModal } = require('./modals/SSModal')
const { default: JLModal } = require('./modals/JLModal')
const { default: KWModal } = require('./modals/KWModal')

const { default: MMModal } = require('./modals/MMModal')
const { default: CGModal } = require('./modals/CGModal')
const { default: DJModal } = require('./modals/DJModal')

const { default: BBModal } = require('./modals/BBModal')
const { default: ABModal } = require('./modals/ABModal')



export const data = {
    data : [

        {
            "id": 1,
            "title": "Prof. Anthony Finkelstein",
            "image": AF_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Engineering Strategic Advantage in National Security </span> - Click below to find out more and join the talk!</>,
            "alt": "- 01/02/2021 5pm GMT -",
            "ctaText": "Read More",
            "tags": 'Chief Scientific Adviser for National Security to HM Government',
            "modal": <AFModal/>
        },

        {
            "id": 2,
            "title": "Prof. John Loughhead",
            "image": JL_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Energy in a Warming World</span> - Click below to find out more and join the talk!</>,
            "alt": "- 15/02/2021 5pm GMT -",
            "ctaText": "Read More",
            "tags": 'Chief Scientific Adviser at the Department for Business, Energy and Industrial Strategy 2016-2020',
            "modal": <JLModal/>
        },

        {
            "id": 3,
            "title": "Prof. Simone Severini",
            "image": SS_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Quantum Computing: Keep it real</span> - Click below to find out more and join the talk!</>,
            "alt": "- 23/02/2021 5pm GMT -",
            "ctaText": "Read More",
            "tags": 'Director of Quantum Computing at Amazon Web Services',
            "modal": <SSModal/>
        },

        {
            "id": 4,
            "title": "Prof. Karen Willcox",
            "image": KW_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Digital Twins and Unmanned Vehicles</span> - Click below to find out more and join the talk!</>,
            "alt": "- 11/03/2021 5pm GMT -",
            "ctaText": "Read More",
            "tags": 'Director, Oden Institute for Computational Engineering and Sciences',
            "modal": <KWModal/>
        },

        {
            "id": 5,
            "title": "Mark McMillan",
            "image": MM_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Adventures in Automated Trading: I’m an Engineer, honest!</span> - Click below to find out more and join the talk!</>,
            "alt": "- 14/10/2021 6pm BST -",
            "ctaText": "Read More",
            "tags": 'Head of Algorithmic Trading Quants. Standard Chartered.',
            "modal": <MMModal/>
        },

        {
            "id": 6,
            "title": "Claudio Galeazzi",
            "image": CG_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>European Space Agency: Taking the pulse of the Earth</span> - Click below to find out more and join the talk!</>,
            "alt": "- 10/11/2021 5:30pm GMT -",
            "ctaText": "Read More",
            "tags": 'CIMR Project Manager. The European Space Agency.',
            "modal": <CGModal/>
        },

        {
            "id": 7,
            "title": "Dr David Jones",
            "image": DJ_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>From Academic to Real-World Engineering - Shipwrecks, Collapses, Explosions, and Lawyers.</span> - Click below to find out more and join the talk!</>,
            "alt": "- 22/11/2021 6pm GMT -",
            "ctaText": "Read More",
            "tags": "Life fellow, ex-President, ex-Master. Christ's College.",
            "modal": <DJModal/>
        },

        {
            "id": 8,
            "title": "Dr Andrew Blake",
            "image": AB_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>AI for Humans</span> - Click below to find out more and join the talk!</>,
            "alt": "- 04/03/2022 5pm GMT -",
            "ctaText": "Read More",
            "tags": "Chairman of Samsung’s AI Research Centre",
            "modal": <ABModal/>
        },

        {
            "id": 9,
            "title": "Billy Boyle",
            "image": BB_photo,
            "desc": <><span style={{fontWeight: 'bold'}}>Breath Biopsy: Developing A Breathalyzer for Disease</span> - Click below to find out more and join the talk!</>,
            "alt": "- 08/03/2022 6pm GMT -",
            "ctaText": "Read More",
            "tags": "CEO Owlstone Medical, judge for Cancer Research UK",
            "modal": <BBModal/>
        },

        

        

        

        

        

        

        

        

        
    ]
    
}
