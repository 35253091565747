import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
  } from "@fortawesome/free-brands-svg-icons";
import {SocialContainer, Facebook, Youtube} from './SocialElements'

const SocialSection = () => {
    return (
        <SocialContainer>
            <Youtube href="https://www.youtube.com/channel/UC5EE8buLTxorNk48LWdyG5A">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
            </Youtube>
            <Facebook href="https://www.facebook.com/groups/christsengineering/">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </Facebook>
        </SocialContainer>
    )
}

export default SocialSection
