import propTypes from 'prop-types'
import {ModalContainer, ModalWrapper, Button, CloseButton} from '../CardInfoSection/modals/ModalElements'

const React = require('react');
const ReactDOM = require('react-dom');
const AriaModal = require('react-aria-modal');


class ModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: false
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
  };

  render() {
    const modal = this.state.modalActive
      ? <AriaModal
          titleId="demo-two-title"
          onExit={this.deactivateModal}
          underlayClickExits={true}
          verticallyCenter={true}
        >
            <ModalContainer className="modal">
                        
                <ModalWrapper>
                    <CloseButton id="demo-two-deactivate" onClick={this.deactivateModal}>
                          x
                    </CloseButton>
                    {this.props.modal_content}
                </ModalWrapper>

            </ModalContainer>
          {/* <div id="demo-two-modal" className="modal"> */}
            
            
            
          {/* </div> */}
        </AriaModal>
      : false;

    return (
      <>
        <Button onClick={this.activateModal}>
          {this.props.button_content}
        </Button>
        {modal}
      </>
    );
  }
}

export default ModalButton

// ModalButton.propTypes = {
//     button_content: propTypes.string.isRequired
// }

// ReactDOM.render(<ModalButton />, document.getElementById('demo-two'));